import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Rate, Tooltip } from "antd";
import moment from "moment";
import Image from "next/image";
import Link from "next/link";
import styles from "@buddieshr/styles/love.module.scss";
import { ORIGINS } from "@buddieshr/utils/constants";

const getOriginContent = (origin) => {
  switch (origin) {
    case ORIGINS.G2:
      return (
        <div>
          <Tooltip title="Written on G2">
            <Image src="/img/g2.png" alt="g2 logo" width={22} height={22} />
          </Tooltip>
        </div>
      );
    case ORIGINS.EMAIL:
    default:
      return (
        <div>
          <Tooltip title="Received by email">
            <MailOutlined />
          </Tooltip>
        </div>
      );
  }
};

const LoveCard = ({
  content,
  name,
  profilePicture,
  date,
  role,
  origin,
  link,
  showStars = false,
}) => (
  <div className={styles.loveElementWrapper}>
    <div className={styles.loveElementInner}>
      <div className={styles.loveElementNameWrapper}>
        <Avatar
          className={styles.loveElementNameAvatar}
          src={profilePicture}
          size={44}
          style={{ minWidth: 44 }}
          icon={<UserOutlined />}
          alt={`Profile of ${name}`}
        />
        <div className={styles.loveElementNameContent}>
          <span className={styles.loveElementName}>{name}</span>
          {role && role.length > 0 && (
            <div className={styles.loveElementRole}>{role}</div>
          )}
        </div>
        {origin && (
          <div className={styles.loveElementOrigin}>
            <Link target="_blank" href={link || ""}>
              {getOriginContent(origin)}
            </Link>
          </div>
        )}
      </div>
      {showStars && (
        <Rate style={{ marginTop: 10 }} defaultValue={5} disabled />
      )}
      <div className={styles.loveElementContent}>{content}</div>
      {date && (
        <div className={styles.loveElementDate}>
          {moment(new Date(date)).format("MMM DD, YYYY")}
        </div>
      )}
    </div>
  </div>
);

export default LoveCard;
