import { Button, Tag } from "antd";
import TextBackground from "./text/textBackground";
import styles from "@buddieshr/styles/components/dreamTeam.module.scss";
import Image from "next/image";
import { PRODUCTS } from "@buddieshr/utils/constants-products";
import PageWrapper from "./templates/pageWrapper";
import AnimateOnShow from "./animateOnShow";
import IndividualAppVignette from "./IndividualAppVignette";

const Item = ({ product, className = "" }) => (
  <AnimateOnShow>
    <IndividualAppVignette app={product} className={className} />
  </AnimateOnShow>
);

const DreamTeam = () => (
  <PageWrapper className={styles.wrapper}>
    <h2 className={styles.title} id="products">
      Meet the&nbsp;<TextBackground>dream team</TextBackground>{" "}
      <Image
        src="/img/animated-emojis/Star-Struck.png"
        alt="Star-Struck"
        width="40"
        height="40"
        style={{
          marginLeft: 10,
        }}
      />
    </h2>
    <div className={styles.subtitle}>
      Some people call them “the X-men of culture”, others “the avengers of
      employee engagement”, anyway, you and your employees will love’em!
    </div>
    <div className={styles.list}>
      {PRODUCTS.map((p, i) =>
        p.show ? (
          <Item product={p} key={p.id} className={styles.dreamTeamItem} />
        ) : (
          <></>
        )
      )}
    </div>
    <div className={styles.tagWrapper}>
      <Tag
        className={styles.tag}
        color="purple"
        icon={
          <Image
            style={{
              //   marginBottom: "4px",
              marginRight: "10px",
            }}
            src="/img/slack.png"
            alt="slack logo"
            width={17}
            height={17}
          />
        }
      >
        Each app has been reviewed and validated by Slack
      </Tag>
    </div>
  </PageWrapper>
);

export default DreamTeam;
