import React from "react";
import styles from "@buddieshr/styles/components/visionPanel.module.scss";
import { Tag } from "antd";
import Link from "next/link";
import Image from "next/image";

const VisionPanel = () => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
      <div>
        <Image
          src="/img/animated-emojis/Compass.png"
          alt="Rocket"
          width="110"
          height="110"
          className={styles.image}
        />
      </div>
      <div className={styles.left}>
        <div className={styles.tagWrapper}>
          <Tag className={styles.tag} color="cyan">
            Vision
          </Tag>
        </div>
        <h2 className={styles.title}>
          Employee engagement is our mission and we’re in it together
        </h2>
      </div>
      <div className={styles.actions}>
        <Link href="/about">Learn more about our vision</Link>
      </div>
    </div>
  </div>
);

export default VisionPanel;
