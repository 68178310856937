import React from "react";
import styles from "@buddieshr/styles/components/lovePanel.module.scss";
import Link from "next/link";
import { Button } from "antd";
import LoveCard from "../loveCard";
import { LOVE } from "@buddieshr/utils/constants";
import { useWindowSize } from "@buddieshr/utils/utils";
import Image from "next/image";

const LovePanel = ({
  style = {},
  hideTitle = false,
  hideCTA = false,
  small = false,
  showStars = false,
  filterKeyword,
}) => {
  const size = useWindowSize();
  const limit = size.width < 690 || small ? 3 : 6;

  return (
    <div className={styles.wrapper} style={style}>
      <div className={styles.inner}>
        {!hideTitle && (
          <div className={styles.title}>
            <h2>
              These people were not bribed{" "}
              <Image
                src="/img/animated-emojis/Smiling%20Face%20with%20Halo.png"
                alt="Smiling Face with Halo"
                width="40"
                height="40"
                style={{ marginLeft: 10 }}
              />
            </h2>
          </div>
        )}
        {!hideTitle && <div className={styles.subtitle}>Promise!</div>}
        <div className={styles.content}>
          {LOVE.filter((e) =>
            filterKeyword ? e.content.includes(filterKeyword) : true
          )
            .slice(0, limit)
            .map((l) => (
              <LoveCard key={l.name} {...l} showStars={showStars} />
            ))}
        </div>
        {!hideCTA && (
          <div className={styles.actions}>
            <Link href="/love">
              <Button type="primary" size="large" style={{ minWidth: 220 }}>
                Read more love ❤️
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default LovePanel;
